import { IEnvironment } from "./environment.interface";

const host = 'https://api-dev.guestup.com/go-skiset-mypassfeet/v1';

export const environment: IEnvironment = {
  production: false,
  env: 'DEV',
  splashScreen: false,
  apiUrl: host,
};
