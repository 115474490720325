import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiResponse, AuthApiResponse, loginDto } from "@core/models";
import { environment } from "@env/environment";
import { catchError, map, Observable, of, tap } from "rxjs";
import { MYPASS_FEET_USER_KEY } from "./user.service";
import { Router } from "@angular/router";


@Injectable()
export class AuthService {

  private readonly TOKEN_KEY = 'token_key';

  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);

  setToken(_token: string): void {
    localStorage.setItem(this.TOKEN_KEY, _token);
  }

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  login(dto: loginDto): Observable<ApiResponse<AuthApiResponse>> {
    return this.http.post<ApiResponse<AuthApiResponse>>(`${environment.apiUrl}/login`, { ...dto }).pipe(
      tap((response: ApiResponse<AuthApiResponse>) => {
        sessionStorage.setItem(MYPASS_FEET_USER_KEY, JSON.stringify(response.data.user));
        this.setToken(response.data.token);
      }),
      map(response => response)
    );
  }

  logout() {
    this.http.get(`${environment.apiUrl}/logout`).subscribe({
      complete: () => {
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(["/"]);
      }
    });
  }

  // Refresh token method
  refreshToken(): Observable<string | null> {
    return this.http.get<{token: string}>(`${environment.apiUrl}/refresh-token`).pipe(
      tap(response => {
        localStorage.setItem(this.TOKEN_KEY, response.token);
      }),
      map(response => response.token),
      catchError(() => {
        this.logout();
        return of(null);
      })
    );
  }
}
