import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse, AuthApiResponse, CreateUserDto, ScanCode, UserBase } from '@core/models';
import { environment } from '@env/environment';
import { map, Observable, tap } from 'rxjs';
import { AuthService } from './auth.service';

export const MYPASS_FEET_USER_KEY = 'my-passfeet-user-key';
export const MYPASS_FEET_IS_EXPAND_GUEST = 'my-pass-feet-is-expand-guest';

@Injectable()
export class UserService {
  private _user: UserBase & ScanCode;

  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);

  set user(user: UserBase & ScanCode) {
    sessionStorage.setItem(MYPASS_FEET_USER_KEY, JSON.stringify(user));
    this._user = user;
  }

  get user(): UserBase & ScanCode {
    if (!this._user && sessionStorage.getItem(MYPASS_FEET_USER_KEY)) {
      this._user = JSON.parse(sessionStorage.getItem(MYPASS_FEET_USER_KEY));
    }
    return this._user;
  }

  set isExpandGuest(isExpand: number) {
    sessionStorage.setItem(MYPASS_FEET_IS_EXPAND_GUEST, isExpand.toString());
  }

  get isExpandGuest(): number {
    if (sessionStorage.getItem(MYPASS_FEET_IS_EXPAND_GUEST)) {
      return Number(sessionStorage.getItem(MYPASS_FEET_IS_EXPAND_GUEST));
    }
    return 0;
  }

  createUser(dto: CreateUserDto): Observable<ApiResponse<AuthApiResponse>> {
    return this.http.post<ApiResponse<AuthApiResponse>>(`${environment.apiUrl}/users`, {...dto}).pipe(
      tap((response) => {
        this.user = response.data.user;
        this.authService.setToken(response.data.token);
      }),
      map(response => response)
    );
  }

  getUserInfo(): Observable<ApiResponse<UserBase & ScanCode>> {
    return this.http.get<ApiResponse<UserBase & ScanCode>>(`${environment.apiUrl}/users/${this.user.id}`).pipe(
      tap(response => {
        this.user = response.data;
      }),
      map(response => response)
    );
  }

  updateUser(dto: UserBase & ScanCode): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users`, { ...dto });
  }
}

